@import "./styles/navbar.css";
@import "./styles/footer.css";
@import "./styles/home.css";
@import "./styles/about.css";
@import "./styles/contact.css";
@import "./styles/projects.css";
@import "./styles/media-queries.css";

* {
  box-sizing: border-box;
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  color: #000;
}

a:hover {
  color: #EC6164;
}

p {
  line-height: 1.5;
}