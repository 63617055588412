.footer-container {
  position: relative;
}

.footer {
  padding: 1rem 0 2.5rem;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.github-logo {
  width: 6rem;
}

.linked-in-logo {
  margin-top: 2rem;
  width: 5.5rem;
}