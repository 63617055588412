.projects-parent-container h1, .projects-parent-container h2{
  text-align: center;
}

.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-title {
  font-size: 1.75rem;
  color: #EC6164;
  padding-top: 2rem;
}

.project-lift-link {
  padding-bottom: 1rem;
  margin-right: 0;
  text-align: center;
}

.project-baked-link img {
  display: flex;
  margin: auto;
  width: 20rem;
  padding-bottom: 1rem;
}

.project-info {
  padding: 1.6rem;
}

.project-info p {
  margin: 1rem 0 1.5rem;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.a-button {
  text-align: center;
  text-decoration: none;
  border-radius: 25px;
  background: black;
  font-size: 0.8rem;
  border: none;
  height: 2.5rem;
  width: 10rem;
  padding-top: 0.85rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.01);
  font-weight: 600;
  color: white;
}