form {
  
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

.contact-header {
  font-size: 1.25rem;
  padding: 2rem 1rem 1rem;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-button {
  padding: 0.5rem;
}

.contact-info-parent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  padding: 0.5rem;
  width: 55%;
}

.contact-header {
  margin: 0.5rem;
}

.contact-header-2 {
  font-size: 1rem;
}

.contact-info {
  margin: 0.25rem;
}


.submit-button {
  text-align: center;
  text-decoration: none;
  border-radius: 25px;
  background: black;
  font-size: 0.8rem;
  border: none;
  height: 2.5rem;
  width: 5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.01);
  font-weight: 600;
  color: white;
}

.submit-button:hover {
  cursor: pointer;
  color: #EC6164;
}