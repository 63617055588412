@media only screen and (min-width: 600px) {
  .nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
  }

  .nav-logo {
    width: 12rem;
  }

  .nav-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .projects-parent-container {
    margin: 0 auto;
  }

  .project-lift-gif {
    margin-right: 3rem;
  }

  .project-baked-gif {
    margin-right: 3rem;
  }
}

@media only screen and (min-width: 750px) {
  .project-container {
    display: grid;
    grid-template-columns: 350px 400px;
    justify-content: center;
    margin-bottom: 2rem;
  }
}