.city {
  font-weight: 600;
  color: #EC6164;
}

.hobbies {
  list-style-type: none;
}

.hobbies li {
  line-height: 2;
}

.girl-emoji {
  display: absolute;
  margin-left: 1rem;
  margin-bottom: -0.5rem;
  width: 2.5rem;
}

.hobbies-container {
  margin-top: 1rem;
}