.nav-bar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: #F8EFED;
  padding: 0.5rem;
}

.nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
}

.nav-link {
  padding: 1rem;
  text-decoration: none;
}

.nav-logo {
  width: 45vw;
}

.toggle-button {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: black;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .toggle-button {
    display: flex; 
  }

  .hamburger-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-hamburger-logo {
    padding-bottom: 2rem;
  }

  .nav-links {
    flex-direction: column;
    display: none;
    width: 100%;
  }

  .nav-link:hover {
    color: #EC6164;
    text-align: center;
    width: 100%;
  }

  .nav-links-bar-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
    border: 2px solid grey;
    border-radius: 25px;
    padding: 1.5rem 0 1.5rem;
  }

  .nav-links.active {
    display: flex;
  }
  
  .navigate-to {
    font-weight: 600;
    padding: 1rem;
  }

  .menu {
    position: fixed; 
    top: 0;
    left: 0;
    z-index: 50;
    background-color: #F8EFED;
    width: 75%;
    height: 100%;
    padding: 3rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .menuMask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
}