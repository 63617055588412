.outer-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 2rem 1.6rem;
  max-width: 550px;
}

.title {
  color: #EC6164;
  font-size: 1.75rem;
  text-align: center;
  margin: 0;
}

.profile-image {
  display: block;
  border-radius: 50%;
  border: 5px solid #EC6164;
  margin: 0 auto 1rem;
  max-width: 12.5rem;
}

.career-title {
  font-size: 1rem;
  text-align: center;
  padding-bottom: 1rem;
}